// ApprovedImages.js
import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import './ApprovedImages.css';
const ITEMS_PER_PAGE = 10; 

const ApprovedImages = () => {
  const { isLoggedIn } = useAuth();
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.get(`/api/images/approved?page=${currentPage}&limit=${ITEMS_PER_PAGE}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setImages(response.data.approved_images);
        setTotalPages(response.data.pages);
      } catch (error) {
        console.error('Error fetching approved images:', error);
        if (error.response && error.response.status === 403) {
            setShouldRedirect(true);
        }

      }
    };

    fetchImages();
  }, [currentPage]);

  if (!isLoggedIn || shouldRedirect) {
    return <Navigate to="/logout" />;
  }

  const handlePageChange = (newPage) => {
    if(newPage !== '...') {
        setCurrentPage(newPage);
    }
  };

return (
    <div className="approved-images-container">
      <h2>Approved Images</h2>
      <div className="image-gallery">
        {images.map((image, index) => (
          <div key={image.id} className="image-card">
            <img src={image.image_path} alt={`Approved ${index + 1}`} className="image-thumbnail" />
            <div className="image-info">
              <span><p><Link to={`/approval/${image.domain}`}>{image.domain}</Link></p></span>
              <span><p>Approved by: {image.approved_by}</p></span>
              <span><p>Date: {new Date(image.approved_at).toLocaleDateString()}</p></span>
            </div>
          </div>
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pages = [];

  // Determine the first and last page to show in the pagination
  const startPage = Math.max(1, currentPage - 5);
  const endPage = Math.min(totalPages, currentPage + 5);

  // Include the first page and ellipses if necessary
  if (startPage > 1) {
    pages.push(1);
    if (startPage > 2) {
      pages.push('...');
    }
  }

  // Generate the range of page numbers to be displayed
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  // Include ellipses and the last page if necessary
  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      pages.push('...');
    }
    pages.push(totalPages);
  }

  return (
    <div className="pagination">
      {pages.map((page, index) => (
        <button
          key={index}
          onClick={() => page !== '...' && onPageChange(page)}
          className={`page-item ${currentPage === page ? 'active' : ''}`}
          disabled={page === '...'}
        >
          {page}
        </button>
      ))}
    </div>
  );
};

export default ApprovedImages;
