// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/CreateUser.css */
.create-user-container {
  width: 300px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}

.create-user-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

`, "",{"version":3,"sources":["webpack://./src/components/CreateUser.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;EACE,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,4CAA4C;AAC9C;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["/* src/components/CreateUser.css */\n.create-user-container {\n  width: 300px;\n  margin: 50px auto;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);\n}\n\n.create-user-title {\n  font-size: 24px;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
