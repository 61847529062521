// RejectedImages.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link, Navigate } from 'react-router-dom';
import axios from 'axios';
import './RejectedImages.css';
const ITEMS_PER_PAGE = 10; 


const RejectedImages = ({ type }) => {
  const { isLoggedIn } = useAuth();
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const typeQueryParam = type === 'white' ? '&type=white' : 
                       type === 'size' ? '&type=size' : 
                       type === 'quality' ? '&type=quality' : '';
        const response = await axios.get(`/api/images/rejected?page=${currentPage}&limit=${ITEMS_PER_PAGE}${typeQueryParam}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setImages(response.data.rejected_images);
        setTotalPages(response.data.pages);
      } catch (error) {
        console.error('Error fetching rejected images:', error);
        if (error.response && error.response.status === 403) {
            setShouldRedirect(true);
        }

      }
    };



    fetchImages();
  }, [currentPage, type]);

  const handlePageChange = (newPage) => {
    if(newPage !== '...') {
        setCurrentPage(newPage);
    }
  };
  if (!isLoggedIn || shouldRedirect) {
    return <Navigate to="/logout" />;
  }

return (
    <div className="rejected-images-container">
      <h2>Rejected Images</h2>
      <div className="image-gallery">
        {images.map((image, index) => (
          <div key={image.id} className="image-card">
            <img src={image.image_path} alt={`Rejected ${index + 1}`} className="image-thumbnail" />
            <div className="image-info">
              <span><p><Link to={`/approval/${image.domain}`}>{image.domain}</Link></p></span>
              <span><p>Rejected By: {image.rejected_by}</p></span>
              <span><p>Date: {new Date(image.rejected_at).toLocaleDateString()}</p></span>
            </div>
          </div>
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pages = [];

  // Determine the first and last page to show in the pagination
  const startPage = Math.max(1, currentPage - 5);
  const endPage = Math.min(totalPages, currentPage + 5);

  // Include the first page and ellipses if necessary
  if (startPage > 1) {
    pages.push(1);
    if (startPage > 2) {
      pages.push('...');
    }
  }

  // Generate the range of page numbers to be displayed
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  // Include ellipses and the last page if necessary
  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      pages.push('...');
    }
    pages.push(totalPages);
  }

  return (
    <div className="pagination">
      {pages.map((page, index) => (
        <button
          key={index}
          onClick={() => page !== '...' && onPageChange(page)}
          className={`page-item ${currentPage === page ? 'active' : ''}`}
          disabled={page === '...'}
        >
          {page}
        </button>
      ))}
    </div>
  );
};

export default RejectedImages;
