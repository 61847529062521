// src/App.js
import React, { useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
//import { useAxiosInterceptor } from './hooks/useAxiosInterceptor';
import Login from './components/Login';
import CreateUser from './components/CreateUser';
import ApprovalPage from './components/ApprovalPage';
import Dashboard from './components/Dashboard';
import UserManagement from './components/UserManagement';
import UserDetail from './components/UserDetail';
import RejectedImages from './components/RejectedImages';
import ApprovedImages from './components/ApprovedImages';
import Layout from './components/Layout';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute'; // Import the PrivateRoute component
const Logout = () => {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
  }, [logout]);
  return <Navigate to="/login" />;
};

function App() {
  //useAxiosInterceptor();
  useEffect(() => {
    // Set up the Authorization header with the token if it exists
    const token = sessionStorage.getItem('token');
    if (token && token !== 'null') {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      // Remove the Authorization header if no token is found
      delete axios.defaults.headers.common['Authorization'];
    }
  }, []);
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Layout><PrivateRoute><Dashboard /></PrivateRoute></Layout>} />
            <Route path="/create-user" element={<Layout><PrivateRoute><CreateUser /></PrivateRoute></Layout>} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/user-management" element={<Layout><PrivateRoute><UserManagement /></PrivateRoute></Layout>} />
            <Route path="/rejected" element={<Layout><PrivateRoute><RejectedImages /></PrivateRoute></Layout>} />
            <Route path="/rejectedWhite" element={<Layout><PrivateRoute><RejectedImages type="white" /></PrivateRoute></Layout>} />
            <Route path="/rejectedSize" element={<Layout><PrivateRoute><RejectedImages type="size" /></PrivateRoute></Layout>} />
            <Route path="/rejectedQuality" element={<Layout><PrivateRoute><RejectedImages type="quality" /></PrivateRoute></Layout>} />
            <Route path="/approved" element={<Layout><PrivateRoute><ApprovedImages /></PrivateRoute></Layout>} />
            <Route path="/approval" element={<Layout><PrivateRoute><ApprovalPage /></PrivateRoute></Layout>} />
            <Route path="/approval/:domain" element={<Layout><PrivateRoute><ApprovalPage /></PrivateRoute></Layout>} />
            <Route path="/users/:userId" element={<Layout><PrivateRoute><UserDetail /></PrivateRoute></Layout>} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
