// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  width: 300px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}

.login-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.btn-primary {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: #0056b3;
}

.btn-primary:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,4CAA4C;AAC9C;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,6CAA6C;AAC/C;;AAEA;;EAEE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".login-container {\n  width: 300px;\n  margin: 50px auto;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);\n}\n\n.login-title {\n  font-size: 24px;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.form-group {\n  margin-bottom: 15px;\n}\n\n.form-input {\n  width: calc(100% - 20px);\n  padding: 10px;\n  margin-top: 5px;\n  border-radius: 4px;\n  border: 1px solid #ddd;\n}\n\n.btn-primary {\n  width: 100%;\n  padding: 10px;\n  border: none;\n  background-color: #007bff;\n  color: white;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.2s ease-in-out;\n}\n\n.btn-primary:hover,\n.btn-primary:active {\n  background-color: #0056b3;\n}\n\n.btn-primary:disabled {\n  background-color: #aaa;\n  cursor: not-allowed;\n}\n\n.error-message {\n  color: red;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
