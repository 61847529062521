import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; 
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import '../bootstrap.css';
const ChangePasswordModal = ({ show, handleClose }) => {
  const { setToken } = useAuth();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const validatePassword = (password) => {
 	const hasUpperCase = /[A-Z]/.test(password);
	const hasLowerCase = /[a-z]/.test(password);
	const hasDigit = /\d/.test(password);
	const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

	if (password.length <= 10) {
		return "Password must be more than 10 characters.";
	}
	if (!hasUpperCase) {
		return "Password must contain at least one uppercase letter.";
	}
	if (!hasLowerCase) {
		return "Password must contain at least one lowercase letter.";
	}
	if (!hasDigit) {
		return "Password must contain at least one digit.";
	}
	if (!hasSpecialChar) {
		return "Password must contain at least one special character.";
	}
	return null;
  };
  const handleChangePassword = async (e) => {
    e.preventDefault();
    const validationError = validatePassword(newPassword);
    if (newPassword !== confirmPassword) {
      setError("New password does not match confirmation.");
      return;
    }

    if (validationError) {
        setError(validationError);
        return;
    }

    try {
      const response = await axios.post('/api/change-password', {
        old_password: oldPassword,
        new_password: newPassword
      });

      // TODO: Invalidate token after password change
      if (response.status === 200) {
        setToken(null); 
        handleClose(); // Close the modal
      }
    } catch (err) {
      setError(err.response.data.message || "An error occurred while changing the password.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleChangePassword}>
          <Form.Group controlId="formOldPassword">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter old password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formNewPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formConfirmPassword">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Group>

          {error && <div className="alert alert-danger">{error}</div>}

          <Button variant="primary" type="submit">
            Change Password
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
