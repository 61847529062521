import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './ApprovalPage.css';
import { useAuth } from '../contexts/AuthContext';
import { Navigate, useParams, useNavigate } from 'react-router-dom';

const ApprovalPage = () => {
  const [logo, setLogo] = useState(null);
  const [comment, setComment] = useState("");
  const [error, setError] = useState('');
  const [replacementLogo, setReplacementLogo] = useState(null);
  const [newLogoUrl, setNewLogoUrl] = useState("");
  const [logoBgColor, setLogoBgColor] = useState('white');
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  const [commentStatus, setCommentStatus] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const { domain } = useParams();
  const { isLoggedIn, userId } = useAuth();
  const navigate = useNavigate();
  const [whitePixelPercentage, setWhitePixelPercentage] = useState(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const convertSvgToPng = async (svgUrl, callback) => {
  const img = new Image();
  img.src = svgUrl;

  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    const context = canvas.getContext('2d');
    context.drawImage(img, 0, 0);

    const pngUrl = canvas.toDataURL('image/png');
    callback(pngUrl);
  };

  img.onerror = () => {
    console.error('Error loading SVG for conversion');
    callback(null);
  };
};
  const calculateWhitePixels = (imageElement) => {
  // Create a canvas element
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Set canvas dimensions to match the image
  canvas.width = imageElement.width;
  canvas.height = imageElement.height;

  // Draw the image onto the canvas
  ctx.drawImage(imageElement, 0, 0, imageElement.width, imageElement.height);

  // Get the pixel data from the canvas
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  let lightPixels = 0;
  let opaquePixels = 0;
  const threshold = 245; // Threshold for considering a pixel as 'light'
  const alphaThreshold = 200;

  // Iterate over each pixel
  for (let i = 0; i < data.length; i += 4) {
    const r = data[i];     // Red
    const g = data[i + 1]; // Green
    const b = data[i + 2]; // Blue
    const a = data[i + 3];
    // Check if the pixel is light
    if (a > alphaThreshold) {
      opaquePixels++;
      if (r >= threshold && g >= threshold && b >= threshold) {
        lightPixels++;
      }
    }
  }

  // Calculate the percentage of light pixels
  // const totalPixels = canvas.width * canvas.height;
  const percentage = opaquePixels > 0 ? (lightPixels / opaquePixels) * 100 : 0;
  return percentage;
  };
  const toggleLogoBgColor = () => {
    setLogoBgColor(prevColor => prevColor === 'white' ? 'lightgray' : 'white');
  };

  const fetchThumbnail = async (domain) => {
    try {
      setThumbnailLoading(true);
      const response = await axios.get(`/api/thumbnail/https://${domain}`, { responseType: 'arraybuffer' });
      const blob = new Blob([response.data], { type: 'image/png' });
      setThumbnailSrc(URL.createObjectURL(blob));
    } catch (error) {
      console.error('Error fetching thumbnail:', error);
      if (error.response && error.response.status === 403) {
      	setShouldRedirect(true);
      }

    } finally {
      setThumbnailLoading(false);
    }
  };

  const isBase64 = (string) => {
      if (string.includes(';base64,')) {
        return true;
      }
      return false;
  };

  const fetchLogo = async (id) => {
  try {
    const response = await axios.get(`/api/images/${id}`);
    setLogo(response.data);
  } catch (error) {
    console.error('Error fetching logo:', error);
    if (error.response && error.response.status === 403) {
      setShouldRedirect(true);
    }

  }
  };
  const fetchRandomLogo = useCallback(async () => {
    setThumbnailSrc('');
    setWhitePixelPercentage(null);
    try {
        const response = await axios.get('/api/images/random_pending');
        setLogo(response.data);
        navigate('/approval');  // Navigate to approvals
     } catch (error) {
        console.error('Error fetching random logo:', error);
        if (error.response && error.response.status === 403) {
           setShouldRedirect(true);
        }

     }
  }, [setThumbnailSrc, setLogo, navigate, setWhitePixelPercentage]);

  const fetchSpecificLogo = useCallback(async (domain) => {
    setThumbnailSrc('');
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.get(`/api/images/specific_pending/${domain}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
	});
      setLogo(response.data);
    } catch (error) {
      console.error('Error fetching logo for domain:', error);
      if (error.response && error.response.status === 403) {
         setShouldRedirect(true);
      } else {
         fetchRandomLogo();
      }
    }
  }, [setThumbnailSrc, setLogo, fetchRandomLogo]);

  const fetchLogoBasedOnURL = useCallback(() => {
      const path = window.location.pathname;
      const pathSegments = path.split('/');

      const domainIndex = pathSegments.indexOf('approval');

      if (domain) {
        const domain = pathSegments[domainIndex + 1];
        fetchSpecificLogo(domain);
      } else {
        fetchRandomLogo();
      }
  }, [domain, fetchRandomLogo, fetchSpecificLogo]);

  const approveLogo = async () => {
    await axios.put(`/api/images/${logo.id}/approve`);
    fetchRandomLogo();
  };

  const rejectLogo = async () => {
    try {
        await axios.put(`/api/images/${logo.id}/reject`);
    } catch (error) {
        if(error.response && error.response === 409) {
            setError("This logo has been approved already by this user")
            setTimeout(() => setError(''), 5000);
        } else if(error.response && error.response === 403) {
            setShouldRedirect(true);
        }
    }
    fetchRandomLogo();
  };

  const rejectLogoWhite = async () => {
    try {
        await axios.put(`/api/images/${logo.id}/reject?type=white`);
    } catch (error) {
        if(error.response && error.response === 409) {
            setError("This logo has been approved already by this user")
            setTimeout(() => setError(''), 5000);
        } else if(error.response && error.response === 403) {
            setShouldRedirect(true);
        }
    }
    fetchRandomLogo();
  };

  const rejectLogoQuality = async () => {
    try {
        await axios.put(`/api/images/${logo.id}/reject?type=quality`);
    } catch (error) {
        if(error.response && error.response === 409) {
            setError("This logo has been approved already by this user")
            setTimeout(() => setError(''), 5000);
        } else if(error.response && error.response === 403) {
            setShouldRedirect(true);
        }
    }
    fetchRandomLogo();
  };

  const rejectLogoSize = async () => {
    try {
        await axios.put(`/api/images/${logo.id}/reject?type=size`);
    } catch (error) {
        if(error.response && error.response === 409) {
            setError("This logo has been approved already by this user")
            setTimeout(() => setError(''), 5000);
        } else if(error.response && error.response === 403) {
            setShouldRedirect(true);
        }
    }
    fetchRandomLogo();
  };

  const addComment = async () => {
    try {
      await axios.post(`/api/images/${logo.id}/comments`, { comment_text: comment, commented_by: userId });
      setComment('');  // Clear comment input
      setCommentStatus('Comment saved successfully!');  // Notify the user
      setTimeout(() => setCommentStatus(''), 5000); // Clear error message
    } catch (error) {
      console.error('Error saving comment:', error);
      setCommentStatus('Failed to save comment.');  // Notify the user about the error
    }
  };


  const uploadReplacement = async () => {
  let formData = new FormData();
  if (!replacementLogo && !newLogoUrl) {
    setUploadStatus('Please provide a file or a URL.');
    setTimeout(() => setUploadStatus(''), 5000);
    return; // Exit the function if neither a file nor a URL is provided
  }

  if (replacementLogo && newLogoUrl) {
    setUploadStatus('Please provide either a file or a URL, not both.');
    setTimeout(() => setUploadStatus(''), 5000);
    return; // Exit the function if both a file and a URL are provided
  }
  if (replacementLogo) {
    // User uploaded a file
    formData.append('file', replacementLogo);
  } else if (newLogoUrl) {
    // User provided a URL
    formData.append('url', newLogoUrl);
  }
  formData.append('domain', logo.domain);
  formData.append('uploaded_by', userId);

  try {
    await axios.post('/api/images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    setReplacementLogo(null);
    setNewLogoUrl('');
    setUploadStatus('Upload Successful!');
    setTimeout(() => setUploadStatus(''), 5000);
    fetchLogo(logo.id);

  } catch (error) {
    console.error('Error uploading image:', error);
    setUploadStatus('Failed to upload. Please try again.');
    setTimeout(() => setUploadStatus(''), 5000);

  }
};
  useEffect(() => {
    fetchLogoBasedOnURL();
  }, [fetchLogoBasedOnURL]);

  useEffect(() => {
  if (logo && logo.image_path.endsWith('.svg')) {
    console.log("Converting SVG");
    console.log(logo.image_path);
    convertSvgToPng(logo.image_path, (pngUrl) => {
      if (pngUrl) {
        setLogo({ ...logo, image_path: pngUrl });
      } else {
        console.log("Couldn't convert SVG");
      }
    });
  }
}, [logo]);
if (!isLoggedIn || shouldRedirect) {
    return <Navigate to="/logout" />;
  }

return (
    <div className="approval-page">
      <div className="navbar">
        <button className="btn btn-primary btn-next-logo" onClick={fetchRandomLogo}>Next Logo</button>
      </div>
      
      {logo ? (
        <div className="logo-container">
          <div className="app-shop-en bg-white fixed right-4 top-4 w-[300px] min-h-[290px] flex flex-col mx-auto rounded-xl shadow-xl translate-y-[0%]"><header className="min-w-full min-h-[50px] py-4 px-4 flex items-center justify-between"><img src="https://cdn-de-dev.zamu.com/get_started128.png" className="!h-[22px] !w-[22px]" alt="Zamu" /><button className="close-button opacity-80"></button></header><main className="min-w-full p-4"><section className="flex flex-col items-center">
          <div className="logo-display" style={{backgroundColor: logoBgColor, padding: '10px', textAlign: '        center'}}>
              {logo.image_path.endsWith('.svg') ? (
            <embed 
              src={isBase64(logo.image_path) ? logo.image_path : `${logo.image_path}?${new Date().getTime()}`}
              type="image/svg+xml" 
              className="logo-img"
            />
          ) : (
            <img 
              src={isBase64(logo.image_path) ? logo.image_path : `${logo.image_path}?${new Date().getTime()}`} 
              alt="logo" 
              className="logo-img"
              onLoad={(e) => {
    		const percentage = calculateWhitePixels(e.target);
		// Handle the percentage (e.g., store it in a state or log it)
                setWhitePixelPercentage(percentage);
	      }}
            />
          )}
          </div>
          <div className="flex flex-col items-center justify-center gap-4 mt-7 w-full"><div className="h1 text-center text-xl mb-2"><span className="text-secondary-300">1.2%</span> Cashback</div><button className="px-2 py-4 tracking-[0.4px] rounded-lg relative font-zamuinter font-semibold disabled:cursor-default cursor-pointer disabled:pointer-events-none select-none antialiased button-text ease-in-out transition-all transition duration-200 w-full block text-base border bg-primary-approval text-white disabled:bg-gray-100 disabled:text-gray-300">Activate Now</button><div className="link">No, I'll activate later</div></div></section></main></div>
          {whitePixelPercentage !== null && (
          <p>Percentage of light colored pixels: {Math.round(whitePixelPercentage)}%</p>
          )}
          <button className="btn btn-secondary" onClick={toggleLogoBgColor}>Toggle Darker Background</button>
          
          <h2 className="domain-title">Domain: {logo.domain}</h2>
          
          <div className="approval-buttons">
            <button className="btn btn-success" onClick={approveLogo} disabled={logo.status === 'Approved'}>Approve</button>
            <button className="btn btn-danger" onClick={rejectLogo}>Reject</button>
            <button className="btn btn-danger" onClick={rejectLogoWhite}>Reject White Logo</button>
            <button className="btn btn-danger" onClick={rejectLogoQuality}>Reject Quality</button>
            <button className="btn btn-danger" onClick={rejectLogoSize}>Reject Size</button>
         </div>
         {error && <p>{error}</p>}

          <div>
          
          <div className="comment-section">
            <input 
              type="text" 
              value={comment} 
              onChange={(e) => setComment(e.target.value)} 
              placeholder="Add a comment"
            />
            <button onClick={addComment}>Submit Comment</button>

            {commentStatus && <p>{commentStatus}</p>}
          </div>
          </div>
          
          <div className="upload-section">
            <input 
              type="file" 
              onChange={(e) => {
                setReplacementLogo(e.target.files[0]);
              }} 
              className="input-file"
            />
            <input 
              type="text" 
              value={newLogoUrl} 
              onChange={(e) => setNewLogoUrl(e.target.value)} 
              placeholder="Or specify a URL"
              className="input-url"
            />
            <button className="btn btn-warning" onClick={uploadReplacement}>Upload Replacement</button>
            {uploadStatus && <p>{uploadStatus}</p>}
          </div>
          
          <div className="thumbnail-section">
            <h3>Preview of {logo?.domain}</h3>
            <button className="btn btn-secondary" onClick={() => fetchThumbnail(logo.domain)}>Load Preview</button>
            <a href={`https://${logo?.domain}`} target="_blank" rel="noopener noreferrer" className="btn btn-secondary" style={{marginLeft: '10px'}}>Open Site on Tab</a>
            {thumbnailLoading ? (
              <p>Loading preview...</p>
            ) : thumbnailSrc ? (
              <img src={thumbnailSrc} alt="Website Thumbnail" className="thumbnail-img" />
            ) : (
              <p>No preview available</p>
            )}
          </div>
        </div>
      ) : (
        <p>Loading logo...</p>
      )}
    </div>
  );
};

export default ApprovalPage;
