import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUsers, FaSignOutAlt, FaHome, FaCheckSquare, FaKey } from 'react-icons/fa'; 
import './Sidebar.css';
import { useAuth } from '../contexts/AuthContext';
import ChangePasswordModal from './ChangePasswordModal';

const Sidebar = () => {
  const { userId } = useAuth();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const handleOpenModal = () => setShowChangePasswordModal(true);
  const handleCloseModal = () => setShowChangePasswordModal(false);

  return (
    <div className="sidebar">
      <div className="sidebar-logo-container">
        <img src="https://cdn-de.zamu.com/zamulogo_wordmark_w_69625c5b3a.png" 
             className="sidebar-logo" 
             alt="Zamu Logo">
        </img>
      </div>
      <ul className="sidebar-menu">
        <li className="menu-item">
          <FaHome className="menu-icon" />
          <Link to="/dashboard">Dashboard</Link>
        </li>
        <li className="menu-item">
          <FaCheckSquare className="menu-icon" />
          <Link to="/approval">Approval Page</Link>
        </li>
        {
          // Render this menu item only if Ids of users with access
          // TODO: RBAC
          (userId === "2" || userId === "3")  && (
            <li className="menu-item">
              <FaUsers className="menu-icon" />
              <Link to="/user-management">User Management</Link>
            </li>
          )
        }
      </ul>
      <ul className="sidebar-menu logout-menu">
        <li className="menu-item" onClick={handleOpenModal}>
          <FaKey className="menu-icon" />
          <span>Change Password</span>
        </li>
        <li className="menu-item">
          <FaSignOutAlt className="menu-icon" />
          <Link to="/logout">Logout</Link>
        </li>
      </ul>
      <ChangePasswordModal show={showChangePasswordModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default Sidebar;
