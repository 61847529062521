// src/components/CreateUser.js
import React, { useState } from 'react';
import axios from 'axios';
import './CreateUserModal.css'; 

const CreateUser = ({ onUserCreated, onClose }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError('');
        try {
            const response = await axios.post('https://logos.zamu.com/api/users', { username, password });
            console.log(response.data);
            if (onUserCreated) {
                onUserCreated(response.data);
            }
            if (onClose) {
                onClose();
            }
        } catch (error) {
            console.error('Error creating user:', error);
            setError('Failed to create user');
        }
        setIsSubmitting(false);
    };

    return (
        <div className="create-user-container">
            <h2 className="create-user-title">Create User</h2>
            <form className="create-user-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input 
                        type="text" 
                        id="username" 
                        value={username} 
                        onChange={(e) => setUsername(e.target.value)} 
                        required 
                        className="form-input" 
                        placeholder="Enter a username"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input 
                        type="password" 
                        id="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required 
                        className="form-input" 
                        placeholder="Enter a password"
                    />
                </div>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? 'Creating...' : 'Create'}
                </button>
                {error && <p className="error-message">{error}</p>}
            </form>
        </div>
    );
};

export default CreateUser;
