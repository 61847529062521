import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import './UserManagement.css';
import { FiPlus } from 'react-icons/fi';
import CreateUserModal from './CreateUserModal';
import { Navigate, Link } from 'react-router-dom';

const UserManagement = () => {
  const { isLoggedIn } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.get('/api/users', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        setError('Failed to fetch users. Please try again.');
        if (error.response && error.response.status === 403) {
	    setShouldRedirect(true);
        }

      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await axios.delete(`/api/users/${userId}`); 
        setUsers(users.filter((user) => user.user_id !== userId));
      } catch (error) {
        console.error('Error deleting user:', error);
        setError('Failed to delete user. Please try again.');
      }
    }
  };

  if (!isLoggedIn || shouldRedirect) {
    return <Navigate to="/logout" />;
  } 

  if (loading) {
    return <div className="loading-indicator">Loading users...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="user-management">
      <h1>User Management</h1>
      <button className="create-user-btn" onClick={() => setShowCreateModal(true)}>
        <FiPlus /> Add User
      </button>
      {/* Table structure for user display */}
      <table className="user-table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Images Approved</th>
            <th>Images Rejected</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>
                <Link to={`/users/${user.user_id}`}>{user.username}</Link>
              </td>
              <td>{user.approvedImagesCount || 0}</td>
              <td>{user.rejectedImagesCount || 0}</td>
              <td>
                {/* Delete prompt for confirmation */}
                <button className="delete-btn" onClick={() => handleDeleteUser(user.user_id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showCreateModal && (
  <CreateUserModal
    onUserCreated={(newUser) => {
      setUsers([...users, newUser]); // Add the new user to the existing users array
      setShowCreateModal(false); // Close the modal
    }}
    onClose={() => setShowCreateModal(false)}
  />
)}
    </div>
  );
};

export default UserManagement;
