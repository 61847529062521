import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import './Login.css'; 

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, setIsLoggedIn, setUserId, setToken} = useAuth();
  const funnyResponses = [
    "🤡 Try XSS next ?",
    "🔮 I looked into the crystal ball and saw... nope, no successful hacks today.",
    "🎩✨ And like that, your hack has vanished into thin air!",
    "🛡️  Your attack was super effective...",
    "🧙‍ Magic shield up!",
    "🧼 I prefer my inputs clean and tidy. Try washing your fingers/code first."
  ];
  const detectMaliciousInput = (input) => {
    const patterns = [
      /[`'";=]+/, // Common SQL injection characters
      /<[^>]*script[^>]*>/i, // Basic XSS script tags
      /{[^}]*}/, // Potential attempt for code injection
      /union select|drop\s+table|update\s+.*\s+set|insert\s+into|delete\s+from/i // Basic SQL injection commands
    ];
    return patterns.some(pattern => pattern.test(input));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (detectMaliciousInput(username)) {
      console.log("Error");
      const funnyResponse = funnyResponses[Math.floor(Math.random() * funnyResponses.length)];
      setError(funnyResponse);
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post('/api/authenticate', { username, password });
      if (response.status === 200) {
        const { token, userId, username } = response.data;

        sessionStorage.setItem('token', token);
        sessionStorage.setItem('userId', userId.toString());
        sessionStorage.setItem('username', username);
        sessionStorage.setItem('isLoggedIn', 'true');

        setIsLoggedIn(true);
        setUserId(response.data.userId);
        setUsername(username);
        setToken(token);
      }
    } catch (err) {
      setError('Authentication failed');
    } finally {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="login-container">
      <h2 className="login-title">Login</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username: </label>
          <input 
            type="text" 
            id="username"
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            placeholder="Enter your username"
            autoComplete="username"
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password: </label>
          <input 
            type="password" 
            id="password"
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            placeholder="Enter your password"
            autoComplete="current-password"
            className="form-input"
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={!username || !password || loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
};

export default Login;
