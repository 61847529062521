// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  display: flex;
}

.main-content {
  flex-grow: 1;
  margin-left: 190px;
}
@media (max-width: 768px) {
	.main-content {
	  flex-grow: 1;
	  margin-left: 150px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;AACA;CACC;GACE,YAAY;GACZ,kBAAkB;CACpB;AACD","sourcesContent":[".layout {\n  display: flex;\n}\n\n.main-content {\n  flex-grow: 1;\n  margin-left: 190px;\n}\n@media (max-width: 768px) {\n\t.main-content {\n\t  flex-grow: 1;\n\t  margin-left: 150px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
