import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import './UserDetail.css'; 

const UserDetail = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { userId } = useParams();

  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        const response = await axios.get(`/api/users/${userId}`);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserDetail();
  }, [userId]);

  if (loading) {
    return <div className="user-details-loading">Loading user details...</div>;
  }

  if (!user) {
    return <div className="user-details-not-found">User not found</div>;
  }

  // Format the created_at date
  const formattedDate = user.created_at ? new Date(user.created_at).toLocaleDateString() : 'N/A';

  return (
    <div className="user-detail-container">
      <h1>{user.username}'s Details</h1>
      <div className="user-info-card">
        <div className="user-info-item"><strong>Username:</strong> {user.username}</div>
        <div className="user-info-item"><strong>Account Created:</strong> {formattedDate}</div>
      </div>

      {/* Images uploaded by the user */}
      <div className="user-images">
        <h2>Latest Uploaded Images</h2>
        <div className="user-images-gallery">
          {user.images.map(image => (
            <img key={image.id} src={image.image_path} alt={image.domain} />
          ))}
        </div>
      </div>


      {/* Last 5 approved images */}
      <div className="user-approved-images">
        <h2>Last 5 Approved Images</h2>
        <div className="user-images-gallery">
          {user.last_approved_images.map(image => (
            <img key={image.id} src={image.image_path} alt={image.domain} />
          ))}
        </div>
      </div>

      {/* Last 5 rejected images */}
      <div className="user-rejected-images">
        <h2>Last 5 Rejected Images</h2>
        <div className="user-images-gallery">
          {user.last_rejected_images.map(image => (
            <img key={image.id} src={image.image_path} alt={image.domain} />
          ))}
        </div>
      </div>

      {/* Comments made by the user */}
      <div className="user-comments">
        <h2>Comments</h2>
        <ul className="user-comments-list">
          {user.comments.map(comment => (
            <li key={comment.id}>
                <h4 className="comment-domain"><Link to={`/approval/${comment.image_domain}`}>{comment.image_domain}</Link></h4>
                <p className="comment-text">{comment.comment_text}</p>
	    </li>
          ))}
        </ul>
      </div>

    </div>
  );
};

export default UserDetail;
