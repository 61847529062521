// src/contexts/AuthContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  // Try to get the initial value from sessionStorage
  const initialLoginState = sessionStorage.getItem('isLoggedIn') === 'true';
  const initialUserId = sessionStorage.getItem('userId') || null;
  const initialUsername = sessionStorage.getItem('username') || null;
  const initialToken = sessionStorage.getItem('token') || null;
  const [isLoggedIn, setIsLoggedIn] = useState(initialLoginState);
  const [userId, setUserId] = useState(initialUserId);
  const [token, setToken] = useState(initialToken);
  const [username, setUsername] = useState(initialUsername);
  const logout = () => {
    // Remove the token from sessionStorage
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.setItem('isLoggedIn', 'false');
    sessionStorage.removeItem('username');
    // Optionally, you can make an API call here to invalidate the token on the backend
    
    // Update state to reflect user is logged out
    setIsLoggedIn(false);
    setUserId(null);
    setToken(null);
    setUsername(null);
  };

  useEffect(() => {
    sessionStorage.setItem('isLoggedIn', isLoggedIn.toString());
    sessionStorage.setItem('userId', userId);
    sessionStorage.setItem('username', username);
    sessionStorage.setItem('token', token);
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [isLoggedIn, userId, token, username]);

  const value = { isLoggedIn, setIsLoggedIn, userId, setUserId, token, setToken, logout, username, setUsername };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
