import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { Navigate, Link } from 'react-router-dom';
import 'chart.js/auto';
import { Pie, Bar } from 'react-chartjs-2';
import './Dashboard.css';

const Dashboard = () => {
  const { isLoggedIn, userId } = useAuth();
  const [topUsers, setTopUsers] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    total_logos: 0,
    approved_logos: 0,
    pending_logos: 0,
    rejected_logos: 0,
    rejected_white_logos: 0,
    rejected_size_logos: 0,
    rejected_quality_logos: 0,
    recent_comments: [],
  });

  const modifyDataForRuben = (users, modifiedApprovals) => {
    return users.map(user => {
      if (user.username.toLowerCase() === 'ruben') { // Check if username is 'ruben'
        return { ...user, approvals: user.approvals+modifiedApprovals, rejections: user.rejections-modifiedApprovals/4 }; // Set an exaggerated number of approvals
      }
      return user;
    });
  };
  const [topUsersData, setTopUsersData] = useState({
    labels: [], // Username labels for the X-axis
    approvalData: [], // Data for approved logos
    rejectionData: [], // Data for rejected logos
  });
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const pieOptions = {
    maintainAspectRatio: true,
    aspectRatio: 1,
  };

  const fetchTopUsers = async () => {
  try {
    const token = sessionStorage.getItem('token');
    const topUsersResponse = await axios.get('/api/top-users', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    let topUsersData = topUsersResponse.data;
    if (userId === '5') {
        topUsersData = modifyDataForRuben(topUsersData, 10000);
    }

    const labels = topUsersData.map(user => user.username);
    const approvalData = topUsersData.map(user => user.approvals);
    const rejectionData = topUsersData.map(user => user.rejections);

    setTopUsersData({
      labels: labels,
      approvalData: approvalData,
      rejectionData: rejectionData,
    });

    let formattedTopUsers = topUsersData.map(user => ({
      username: user.username,
      approvals: user.approvals,
      rejections: user.rejections,
    }));
    setTopUsers(formattedTopUsers);
  } catch (error) {
    console.error("Error fetching top users data:", error);
    if (error.response && error.response.status === 403) {
      setShouldRedirect(true);
    }
  }
  };
  // Fetch top users on mount
  useEffect(() => {
    fetchTopUsers();
  }, []);

  const fetchData = async () => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await axios.get('/api/dashboard', {
      headers: {
        Authorization: `Bearer ${token}` 
      }
    });

    // Enrich each comment with additional details

//      return {
//        ...comment,
//      };
///    }));

    // Set the state with the new data including the enriched comments
    setDashboardData(prevData => ({
      ...prevData,
      ...response.data,
    }));

  } catch (error) {
    console.error("Error fetching data:", error);
    if (error.response && error.response.status === 403) {
      setShouldRedirect(true);
    }
  }
};
  useEffect(() => {
    fetchData();
  }, []);

  if (!isLoggedIn || shouldRedirect) {
    return <Navigate to="/logout" />;
  }

  const pieData = {
    labels: ['Approved', 'Pending', 'Rejected', 'Rejected White', 'Rejected Size', 'Rejected Quality'],
    datasets: [
      {
        data: [dashboardData.approved_logos, dashboardData.pending_logos, dashboardData.rejected_logos, dashboardData.rejected_white_logos, dashboardData.rejected_size_logos, dashboardData.rejected_quality_logos],
        backgroundColor: ['#4CAF50', '#FF9800', '#F44336', '#00BCD4', '#9C27B0', '#FFC107'],
      },
    ],
  };

  const barData = {
    labels: topUsersData.labels,
    datasets: [
      {
        label: 'Approvals',
        data: topUsersData.approvalData,
        backgroundColor: 'rgba(76, 175, 80, 0.5)',
        borderColor: 'rgba(76, 175, 80, 1)',
        borderWidth: 1,
      },
      {
        label: 'Rejections',
        data: topUsersData.rejectionData,
        backgroundColor: 'rgba(244, 67, 54, 0.5)',
        borderColor: 'rgba(244, 67, 54, 1)',
        borderWidth: 1,
      }
    ],
  };

  const barOptions = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };
  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>

      {/* Summary Cards */}
      <div className="summary-cards">
        <div className="card total-logos">
          <h2>Total Logos</h2>
          <p>{dashboardData.total_logos}</p>
        </div>
        <div className="card total-logos-approved">
          <Link to="/approved"><h2>Approved</h2></Link>
          <p>{dashboardData.approved_logos}</p>
        </div>
        <div className="card total-logos-rejected">
          <Link to="/rejected"><h2>Rejected</h2></Link>
          <p>{dashboardData.rejected_logos}</p>
        </div>
        <div className="card total-logos-rejected">
          <Link to="/rejectedWhite"><h2>Rejected White</h2></Link>
          <p>{dashboardData.rejected_white_logos}</p>
        </div>
        <div className="card total-logos-rejected">
          <Link to="/rejectedSize"><h2>Rejected Size</h2></Link>
          <p>{dashboardData.rejected_size_logos}</p>
        </div>
        <div className="card total-logos-rejected">
          <Link to="/rejectedQuality"><h2>Rejected Quality</h2></Link>
          <p>{dashboardData.rejected_quality_logos}</p>
        </div>
        <div className="card approval-page-link">
         <Link to="/approval"><h2>Pending</h2></Link>
        <p>{dashboardData.pending_logos}</p>
      </div>
      </div>

      {/* All charts */}
      <div className="charts-container">
      {/* Pie Chart for Logo Status */}
      <div className="chart pie-chart">
        <h2>Logo Status Distribution</h2>
        <Pie data={pieData} options={pieOptions} />
      </div>

      {/* Bar Chart for Top Users */}
      <div className="chart bar-chart">
        <h2>Top Users</h2>
        <Bar data={barData} options={barOptions} />
      
           <div className="top-users-table">
      <table>
        <thead>
          <tr>
            <th>User</th>
            <th>Approvals</th>
            <th>Rejections</th>
          </tr>
        </thead>
        <tbody>
          {topUsers.map((user, index) => (
            <tr key={index}>
              <td>{user.username}</td>
              <td>{user.approvals}
                  {user.username.toLowerCase() === 'ruben' && userId === '5' && (
        <img src="/nyancat.gif" alt="Nyan Cat" style={{ display: 'inline', marginLeft: '10px', width: '30px', height: '20px' }} />
      )}

              </td>
              <td>{user.rejections}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      </div>


      </div>
      {/* Recent Comments */}
      <h2>Recent Comments</h2>
      <ul className="comments-list">
        {dashboardData.recent_comments.map(comment => (
          <li key={comment.id} className="comment-item">
            <p>{comment.comment_text}</p>
            <p><a href={`https://logos.zamu.com/approval/${comment.image_domain}`}>{comment.image_domain}</a></p>
	    <p><strong>User:</strong> {comment.commented_by}</p>
          </li>
        ))}
      </ul>
    </div>

  );
};

export default Dashboard;
